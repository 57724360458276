<template>
  <div>
    <div class="d-flex report-tab-wrapper">
      <content-loader v-if="!challengeDetailsRequest.loaded" :height="15" :width="500">
        <rect height="15" width="30" />
        <rect height="15" x="40" width="30" />
        <rect height="15" x="80" width="30" />
      </content-loader>
      <div v-else class="d-flex">
        <div
          v-for="(task, i) in tasks"
          :key="task.task_id"
          class="report-tab"
          :class="{ active: selectedStage === i }"
          @click="selectedStage = i"
          >Stage {{ i + 1 }}</div
        >
      </div>
    </div>

    <div class="row code-viewer mt-5">
      <div class="col-7">
        <content-loader
          v-if="!historyReportRequest.loaded || !challengeDetailsRequest.loaded"
          :height="400"
          :width="500"
        >
          <rect height="100%" width="100%" />
        </content-loader>
        <p-editor
          v-else
          :code="currentContent"
          :previous-code="previousCode"
          :read-only="true"
          :programming-language="language"
          :scroll-beyond-last-line="false"
        />
      </div>
      <div class="col-5 flow-column">
        <div class="slider-wrapper row">
          <div class="col-lg-2">
            <button class="control-button play-button ml-0" @click="playHistory">
              <span class="fa icon fa-play" :class="{ 'fa-pause': isPlaying }">&nbsp;</span>&nbsp;<span
                v-if="!isPlaying"
                >&nbsp;Play</span
              ><span v-if="isPlaying">&nbsp;Pause</span>
            </button>
          </div>
          <div class="col-lg-10">
            <p class="text-right mb-0">
              <button class="control-button step-button" @click="decreaseSpeed">
                <span class="fa fa-minus icon"></span>
              </button>
              <span v-b-tooltip.hover.bottom="'Wiedergabegeschwindigkeit'"
                ><span class="fa fa-tachometer"></span> {{ (100 / speed) * 100 }}%</span
              >
              <button class="control-button step-button mr-0" @click="increaseSpeed">
                <span class="fa fa-plus icon"></span>
              </button>
            </p>
          </div>
          <div class="col-lg-12 mt-1">
            <p-playback-slider v-model="index" :min="0" :max="historyReportRequest.apiData.length - 1" />
            <p v-if="typeof historyReportRequest.apiData.length !== 'undefined'" class="text-center mt-0 mb-0">
              <button :disabled="index === 0" class="control-button step-button" @click="decrementIndex">
                <span class="fa fa-arrow-left icon"></span>
              </button>
              Version {{ index }} / {{ historyReportRequest.apiData.length - 1 }}
              <button
                :disabled="index === historyReportRequest.apiData.length - 1"
                class="control-button step-button"
                @click="incrementIndex"
              >
                <span class="fa fa-arrow-right icon"></span
              ></button>
            </p>
          </div>
        </div>
        <content-loader v-if="!flowRequest.loaded" :height="400" :width="500">
          <rect height="100%" width="100%" />
        </content-loader>
        <p-test-viewer v-if="flowRequest.loaded" :task="tasks[selectedStage]" :flow-content="flowRequest.flow_data" />
      </div>
    </div>
  </div>
</template>

<script>
import { services } from "@/main";
import { HistoryReport } from "@/services/quality/QualityServiceResponses";
import { ChallengeDetails } from "@/services/task-management/TaskManagementServiceResponses";
import { FlowResult } from "@/services/flow/FlowServiceResponses";
import PEditor from "@/components/report-base/PEditor";
import PTestViewer from "@/components/report-base/PTestViewer";
import PPlaybackSlider from "@/components/report-base/PPlaybackSlider";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    PEditor,
    PTestViewer,
    PPlaybackSlider,
    ContentLoader,
  },
  props: {
    challengeId: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      historyReportRequest: new HistoryReport(),
      challengeDetailsRequest: new ChallengeDetails(),
      flowRequest: new FlowResult(),
      selectedStage: 0,
      index: 0,
      speed: 100,
      isPlaying: false,
    };
  },
  computed: {
    currentContent: function() {
      return this.historyReportRequest.apiData[this.index].content;
    },
    previousCode: function() {
      if (this.index > 0) {
        return this.historyReportRequest.apiData[this.index - 1].content;
      }
      return null;
    },
    tasks: function() {
      return this.challengeDetailsRequest.apiData.tasks;
    },
    language: function() {
      return this.challengeDetailsRequest.apiData.technology.language.toLowerCase();
    },
  },
  watch: {
    "historyReportRequest.loaded": function() {
      if (!this.historyReportRequest.hasLoaded()) {
        return;
      }

      this.index = this.historyReportRequest.apiData.length - 1;
    },
    "challengeDetailsRequest.loaded": function() {
      if (!this.challengeDetailsRequest.hasLoaded()) {
        return;
      }
      this.historyReportRequest = services.qualityService.getHistoryReport(
        this.challengeId,
        this.userId,
        this.challengeDetailsRequest.apiData.files[this.tasks[this.selectedStage].task_id][0],
        this.tasks[this.selectedStage].task_id
      );
      this.flowRequest = services.flowService.getFlowContent(this.tasks[this.selectedStage].task_id);
    },
    selectedStage: function() {
      this.historyReportRequest = services.qualityService.getHistoryReport(
        this.challengeId,
        this.userId,
        this.challengeDetailsRequest.apiData.files[this.tasks[this.selectedStage].task_id][0],
        this.tasks[this.selectedStage].task_id
      );
      this.flowRequest = services.flowService.getFlowContent(this.tasks[this.selectedStage].task_id);
    },
  },
  mounted() {
    this.challengeDetailsRequest = services.taskManagementService.getChallengeDetails(this.challengeId);
  },
  methods: {
    incrementIndex: function() {
      if (this.index < this.historyReportRequest.apiData.length - 1) {
        this.index++;
      }
    },
    decrementIndex: function() {
      if (this.index > 0) {
        this.index--;
      }
    },
    playHistory() {
      if (this.isPlaying) {
        return this.stopHistory();
      }

      let versionIndex = parseInt(this.index);
      if (versionIndex === this.historyReportRequest.apiData.length - 1) {
        this.index = 0;
      }

      let play = function() {
        if (!this.isPlaying) {
          return;
        }
        let versionIndex = parseInt(this.index) + 1;
        if (versionIndex >= this.historyReportRequest.apiData.length) {
          this.isPlaying = false;
          return;
        }
        this.index = versionIndex;
        setTimeout(play.bind(this), this.speed);
      };
      this.isPlaying = true;
      setTimeout(play.bind(this), this.speed);
    },
    stopHistory() {
      this.isPlaying = false;
    },
    increaseSpeed() {
      let speed = Math.floor(this.speed / 2);
      if (speed < 25) {
        return;
      }
      this.speed = speed;
    },
    decreaseSpeed() {
      let speed = Math.floor(this.speed * 2);
      if (speed >= 1000) {
        return;
      }
      this.speed = speed;
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  justify-content: center;
}

.control-button:hover {
  background-color: rgb(72, 133, 122);
  background: linear-gradient(0deg, #49887c, rgb(64, 119, 109));
}

.step-button.control-button:hover {
  background: #a9a9a9;
}

.step-button.control-button {
  transition: background linear 0.3s;
  display: inline-block;
  line-height: initial;
  font-size: 15px;
  background: #cbcbcb;
}
.play-button .fa {
  font-size: 0.7rem;
  margin-right: 5px;
}
.control-button {
  background-color: $eh-green;
  background: linear-gradient(0deg, $eh-green, rgb(72, 133, 122));
  color: white;
  font-size: 0.8rem;
  text-transform: uppercase;
  height: 1.5rem;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;

  &:focus {
    outline: 0;
  }

  &:disabled {
    background-color: $hover-grey;
  }
}

.report-tab-wrapper {
  margin-bottom: 4rem;
  min-height: 1.6rem;
}

.report-tab {
  background-color: white;
  padding: 0.5rem 1rem;
  margin-right: 2rem;

  &:hover {
    cursor: pointer;
  }

  &:hover:not(.active) {
    background-color: $eh-20;
  }
}

.active {
  background-color: $eh-green;
  color: white;
}

.playback-information {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 1.4rem;

  svg {
    width: 400px;
  }

  .steps {
    margin-right: 2rem;
  }

  .information-icon {
    color: $eh-green;
    font-size: 1.6rem;
  }
}

.slider-wrapper {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2rem;
  background: rgba(0, 0, 0, 0.01);
  padding: 20px 0 15px 0;
  border-radius: 5px;
  max-width: 900px;
}
</style>
