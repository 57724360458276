<template>
  <div>
    <c-lead-header :challenge-id="challengeId" :lead-id="leadId" :sponsored="sponsored" />
    <div class="row">
      <div class="d-flex report-tab-wrapper col-lg-8">
        <div class="report-tab" :class="{ active: selectedTab === 'SCORE' }" @click="selectedTab = 'SCORE'">
          Score
        </div>
        <div class="report-tab" :class="{ active: selectedTab === 'METRICS' }" @click="selectedTab = 'METRICS'">
          Metriken
        </div>
        <div class="report-tab" :class="{ active: selectedTab === 'CODE' }" @click="selectedTab = 'CODE'">
          Code Review
        </div>
      </div>
    </div>
    <p-score
      v-if="selectedTab === 'SCORE'"
      :measures="measures.apiData"
      :report-details="reportDetails.apiData"
      :lead-data="leadResult.apiData"
      :loaded="leadResult.loaded"
      :measures-loaded="measures.loaded"
      :details-loaded="reportDetails.loaded"
    />
    <p-metrics v-if="selectedTab === 'METRICS' && measures.loaded" :measures="measures.apiData" />
    <c-code v-if="selectedTab === 'CODE'" :challenge-id="challengeId" :user-id="leadResult.apiData.lead.userId" />
  </div>
</template>

<script>
import CLeadHeader from "@/components/report/CLeadHeader";
import PMetrics from "@/components/report-base/PMetrics";
import PScore from "@/components/report/PScore";
import CCode from "@/components/report/CCode";
import { QualityReport, Scores } from "@/services/quality/QualityServiceResponses";
import { services } from "@/main";
import { eventBus } from "@/eventbus";
import { SponsoredLeadResult } from "../../services/company/CompanyServiceResponses";
import { Measures } from "../../services/quality/QualityServiceResponses";

export default {
  components: {
    CLeadHeader,
    PMetrics,
    PScore,
    CCode,
  },
  props: {
    challengeId: {
      type: String,
      default: "",
    },
    sponsored: {
      type: String,
      default: "",
    },
    leadId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedTab: "SCORE",
      leadResult: new SponsoredLeadResult(),
      reportDetails: new QualityReport(),
      measures: new Measures(),
    };
  },
  watch: {
    "leadResult.loaded": function() {
      if (!this.leadResult.hasLoaded()) {
        return;
      }

      this.reportDetails = services.qualityService.getQualityReport(
        this.challengeId,
        this.leadResult.apiData.lead.userId
      );
      this.measures = services.qualityService.getMeasures(this.challengeId, this.leadResult.apiData.lead.userId);
    },
  },
  mounted() {
    this.leadResult =
      this.sponsored === "sponsored"
        ? services.companyService.getSponsoredLead(this.leadId)
        : services.companyService.getPoolLead(this.leadId);
  },
};
</script>

<style lang="scss" scoped>
.report-tab-row {
  margin-left: 0;
}

.report-tab-wrapper {
  margin-bottom: 2rem;
}

.report-tab {
  background-color: white;
  padding: 1rem 2rem;
  margin-right: 2rem;

  &:hover {
    cursor: pointer;
  }

  &:hover:not(.active) {
    background-color: $eh-20;
  }
}

.active {
  background-color: $eh-green;
  color: white;
}
</style>
