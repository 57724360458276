import { render, staticRenderFns } from "./PScore.vue?vue&type=template&id=03236333&scoped=true&"
import script from "./PScore.vue?vue&type=script&lang=js&"
export * from "./PScore.vue?vue&type=script&lang=js&"
import style0 from "./PScore.vue?vue&type=style&index=0&id=03236333&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03236333",
  null
  
)

export default component.exports