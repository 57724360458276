<template>
  <h1 v-if="loaded && !error">
    Bewertung für {{ anonymizedUserName }}
    <span class="heading-appendix">{{ challengeTitle }}</span>
  </h1>
  <content-loader v-else :height="15" class="loader-bar">
    <rect height="100%" width="60%" />
  </content-loader>
</template>

<script>
import { ChallengeDetails } from "@/services/task-management/TaskManagementServiceResponses";
import { SponsoredLeadResult } from "@/services/company/CompanyServiceResponses";
import { services } from "@/main";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    ContentLoader,
  },
  props: {
    leadId: {
      type: String,
      default: "",
    },
    sponsored: {
      type: String,
      default: "",
    },
    challengeId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      leadResult: new SponsoredLeadResult(),
      challengeRequest: new ChallengeDetails(),
    };
  },
  computed: {
    loaded() {
      return this.leadResult.loaded && this.challengeRequest.loaded;
    },
    error() {
      return this.leadResult.error && this.challengeRequest.error;
    },
    challengeTitle() {
      return this.challengeRequest.apiData.title;
    },
    anonymizedUserName() {
      return this.leadResult.apiData.lead.anonymizedUserName;
    },
  },
  mounted() {
    this.challengeRequest = services.taskManagementService.getChallengeDetails(this.challengeId);
    this.leadResult =
      this.sponsored === "sponsored"
        ? services.companyService.getSponsoredLead(this.leadId)
        : services.companyService.getPoolLead(this.leadId);
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 4rem;
}
.heading-appendix {
  font-size: 1.2rem;
  color: #777;
}

.loader-bar {
  margin-bottom: 2rem;
}
</style>
