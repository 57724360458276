<template>
  <div>
    <c-lead-report :challenge-id="challengeId" :lead-id="leadId" :sponsored="sponsored" />
  </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import CLeadReport from "@/components/report/CLeadReport";

export default {
  components: { CLeadReport },
  mixins: [AuthenticatedMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    challengeId: {
      type: String,
      default: "",
    },
    sponsored: {
      type: String,
      default: "",
    },
    leadId: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
